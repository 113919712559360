import React from 'react';
import Footer from '../Footer';
//import SecondarySection from '../SecondarySection';
import BgSection from '../BgSection';
import Post from './Post';

function Program() {
    return (
        <>
        <BgSection />
        <Post />
        <Footer />
        </>
    );
}

export default Program