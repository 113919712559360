import React from 'react';
import Footer from '../Footer';
import SecondarySection from '../SecondarySection';

function Contact() {
    return (
        <>
        <SecondarySection />
        <Footer />
        </>
    );
}

export default Contact